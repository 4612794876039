/* eslint-disable react/display-name */
__webpack_public_path__ = `${document.documentElement.dataset.devServerPath !== null && document.documentElement.dataset.devServerPath !== 'null' ? document.documentElement.dataset.devServerPath : document.documentElement.dataset.publicpath}`;
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import './scripts/static';
import './scss/main.scss';
import './utils/i18n';
import React, { Suspense } from 'react';
import ReactDOM from "react-dom/client";
// import * as Sentry from '@sentry/react';
// if (window.location.hostname.indexOf('local') === -1) {
//   Sentry.init({
//     dsn: 'https://b0dcda1d361f4d149f5c35afa010fe48@sentry.openmindonline.it/8',
//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
//   });
// }
const map = new Map();
// if (window.history.scrollRestoration) {
//   window.history.scrollRestoration = 'manual';
// }
function markinitialized(el) {
    el.classList.remove('uninitialized');
    el.classList.remove('initializing');
    el.classList.add('initialized');
    return true;
}
/*init react apps */
function registerComponent(name, render) {
    map.set(name, {
        component: (attrs, el) => (React.createElement(Suspense, { fallback: React.createElement("div", { className: "tmploader", dangerouslySetInnerHTML: { __html: el.innerHTML } }) }, markinitialized(el) && render(attrs))),
    });
}
registerComponents();
const els = document.querySelectorAll(`[data-react]`);
els.forEach((el) => {
    let reactid = el.dataset['react'];
    const dataAttrs = JSON.parse(JSON.stringify(el.dataset));
    // gestisci valori booleani
    Object.keys(dataAttrs).forEach((k) => {
        if (dataAttrs[k] === 'true' || dataAttrs[k] === 'false') {
            dataAttrs[k] = dataAttrs[k] === 'true';
        }
        else if (dataAttrs[k] === 'null') {
            dataAttrs[k] = null;
        }
    });
    let val = map.get(reactid);
    if (!val) {
        console.log('Cannot find react component ' + reactid);
        return;
    }
    el.classList.add('initializing');
    dataAttrs.placeholder = el.innerHTML;
    let comp = val.component(dataAttrs, el);
    const root = ReactDOM.createRoot(el);
    root.render(comp);
});
function registerComponents() {
    const Test = React.lazy(() => import(/* webpackChunkName: "component-test" */ './components/common/Test'));
    registerComponent('test-app', (attrs) => React.createElement(Test, Object.assign({}, attrs)));
    const Bag = React.lazy(() => import(/* webpackChunkName: "component-bag" */ './components/bag/BagLoader'));
    registerComponent('cart-app', (attrs) => React.createElement(Bag, Object.assign({}, attrs)));
    const Checkout = React.lazy(() => import(/* webpackChunkName: "component-checkout" */ './components/checkout/CheckoutLoader'));
    registerComponent('checkout-app', (attrs) => React.createElement(Checkout, Object.assign({}, attrs)));
    const Connectivity = React.lazy(() => import(/* webpackChunkName: "component-connectivity" */ './components/connectivity/ConnectivityLoader'));
    registerComponent('connectivity-app', (attrs) => React.createElement(Connectivity, Object.assign({}, attrs)));
    const ConnectivityCheckout = React.lazy(() => import(/* webpackChunkName: "component-connectivity" */ './components/connectivity/ConnectivityCheckoutLoader'));
    registerComponent('connectivity-checkout-app', (attrs) => React.createElement(ConnectivityCheckout, Object.assign({}, attrs)));
    const AccountAddress = React.lazy(() => import(/* webpackChunkName: "component-forgotpassword-step1" */ './components/account/AccountAddressBookLoader'));
    registerComponent('address-app', (attrs) => React.createElement(AccountAddress, Object.assign({}, attrs)));
}
