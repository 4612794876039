export default async () => {
    let pageDiv = document.querySelector('.page');
    let addToCartButtons = [...document.querySelectorAll('.btn-addtocart')];
    if (!pageDiv || !addToCartButtons) {
        return;
    }
    //@ts-ignore
    let getFullProductsUrl = pageDiv.dataset.getFullProductsUrl;
    let fullProducts = null;
    try {
        fullProducts = await fetch(getFullProductsUrl).then((r) => r.json());
    }
    catch (e) {
        console.error(e);
        return;
    }
    if (!fullProducts || !fullProducts.products || fullProducts.products.length == 0) {
        return;
    }
    addToCartButtons.forEach((button) => {
        //@ts-ignore
        let pid = button.dataset.optionPid;
        if (fullProducts.products.includes(pid)) {
            button.classList.add('disabled');
            button.setAttribute('disabled', '');
            button.innerHTML = '<span>' + fullProducts.label + '</span>';
        }
    });
};
