async function getSvgSprite(svgSprite, svgSpriteElement) {
    if (!svgSprite) {
        const response = await fetch(`${document.documentElement.dataset.publicpath}/images/sprite.svg`, {
            mode: 'no-cors',
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        });
        svgSprite = await response.text();
    }
    if (!svgSpriteElement) {
        svgSpriteElement = document.createElement('div');
        svgSpriteElement.setAttribute('id', 'svg-icons');
        svgSpriteElement.style.display = 'none';
    }
    svgSpriteElement.innerHTML = svgSprite;
    if (document.body) {
        document.body.prepend(svgSpriteElement);
    }
    else {
        getSvgSprite(svgSprite, svgSpriteElement);
    }
}
getSvgSprite('', undefined);
