export default async () => {
    var _a, _b;
    let pageDiv = document.querySelector('.page');
    let addToWishlistButtons = [...document.querySelectorAll('.addtowishlist:not(.d-none)')];
    if (!pageDiv || !addToWishlistButtons) {
        return;
    }
    //@ts-ignore
    let getWishlistUrl = pageDiv.dataset.getWishlistUrl;
    let wishlist;
    try {
        //@ts-ignore
        wishlist = await fetch(getWishlistUrl).then((r) => r.json());
    }
    catch (e) {
        console.error(e);
        return;
    }
    addToWishlistButtons.forEach((button) => {
        if (!wishlist.isRegistered) {
            button.classList.add('d-none');
            button.parentNode.querySelector('a.addtowishlist').classList.remove('d-none');
        }
    });
    //@ts-ignore
    let pidsInWishlist = (_b = (_a = wishlist === null || wishlist === void 0 ? void 0 : wishlist.list) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.map((item) => item.id);
    if (!pidsInWishlist || !pidsInWishlist.length) {
        return;
    }
    addToWishlistButtons.forEach((button) => {
        //@ts-ignore
        let pid = button.dataset.optionVgid;
        if (pidsInWishlist.includes(pid)) {
            button.setAttribute('data-isinwishlist', 'true');
        }
    });
};
