// window.jQuery = window.$ = require('jquery');
__webpack_public_path__ = `${document.documentElement.dataset.devServerPath !== null && document.documentElement.dataset.devServerPath !== 'null'
    ? document.documentElement.dataset.devServerPath
    : document.documentElement.dataset.publicpath}`;
import 'what-input';
import './utilities/svgSprite';
import 'lazysizes';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'lazysizes/plugins/attrchange/ls.attrchange';
// external libraries
import OrderedDispatcher from './libs/orderedDispatcher';
import Cookies from 'js-cookie';
import micromodalInstance from './libs/micromodalInstance';
import customEventBus from 'src/scripts/libs/eventBus';
import fillWishlistHeart from './utilities/fillWishlistHeart';
import buildAddToCartButton from './utilities/buildAddToCartButton';
// vhCheck('ios-gap', {
//   bind: true,
//   redefineVh: true,
//   updateOnTouch: true,
// });
// window._loader_service = new LoaderService();
//ResolutionHandler();
window.eventQueue = new Map();
window.productSearchData = {};
const orderedDispatcher = new OrderedDispatcher();
orderedDispatcher.createAsyncComponents().then(() => {
    orderedDispatcher.observeDomChanges();
});
tippy('[data-tooltip]', {
    content: (el) => el.dataset.tooltip,
    theme: 'light',
    allowHTML: true,
    interactive: true,
    placement: 'bottom',
    appendTo: document.body
});
micromodalInstance.init();
document.addEventListener('DOMContentLoaded', () => {
    customEventBus.on('cartCookie:update', (data) => {
        let cartQty = Cookies.get('iren-cart-qty');
        let header = document.getElementById('header_component');
        if (cartQty && header) {
            let cartContainers = document.getElementsByClassName('cart-container');
            for (const cart of cartContainers) {
                if (parseInt(cartQty, 10) > 0) {
                    cart.classList.add('notify');
                }
                else {
                    cart.classList.remove('notify');
                }
                cart.getElementsByClassName('notify-dot')[0].textContent = cartQty;
            }
        }
    });
    let addToCartButtons = document.querySelectorAll('.btn-addtocart');
    if (addToCartButtons.length > 0)
        buildAddToCartButton();
    let addToWishlistButtons = document.querySelectorAll('.addtowishlist');
    if (addToWishlistButtons.length > 0)
        fillWishlistHeart();
});
window.addEventListener('load', function () {
    datalayerHeader();
    window.addEventListener('resize', function (event) {
        datalayerHeader();
    });
    function datalayerHeader() {
        $('.header_component #header-promo-banner')
            .find('a')
            .each(function () {
            $(this).click(function () {
                if ($(this).attr('href')) {
                    window.dataLayer.push({
                        event: 'cta',
                        element_name: $('#header-promo-banner').find('.description').text().trim(),
                        element_type: 'information_banner',
                        destination: $(this).attr('href'),
                    });
                }
            });
        });
    }
});
window.addEventListener('load', function () {
    datalayerBundle();
    window.addEventListener('resize', function () {
        datalayerBundle();
    });
    function datalayerBundle() {
        $('.bundle-actions').find('.bundle-link-offer').off('click');
        $('.bundle-actions')
            .find('.bundle-link-offer')
            .on('click', function (event) {
            if ($(this).attr('href')) {
                event.preventDefault();
                const elementName = $(this).text().trim();
                const destinationUrl = $(this).attr('href');
                if (window.dataLayer) {
                    window.dataLayer.push({
                        event: 'cta',
                        destination: destinationUrl,
                        element_name: 'link_funnel_self ' + elementName,
                        element_type: 'banner',
                    });
                    window.location.href = destinationUrl;
                }
                else {
                    console.error('dataLayer is not defined');
                }
            }
        });
    }
});
fetch(window.cartSetCookieUrl);
